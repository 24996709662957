<template>
    <div>Esporta Tipi Operazione Membro Consiglio</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Operazione Membro Consiglio", route: "/settings/board-operation-type/index" },
             { title: "Esporta Tipi Operazione Membro Consiglio" }
         ]);
     }
 };
</script>
